import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { kebabCase } from "lodash"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Visit from "../components/shared/visit"

const Tag = props => {
  useEffect(() => {
    document.body.classList.add("resources-page")
  }, [])

  // Destructure the data that actually exists (no FAQ):
  const { articles, tags } = props.data

  // We only have one set of articles now:
  const allArticles = articles.nodes

  // Flatten + trim tags from articles (only "tags" from allContentfulResourceArticles)
  const flattenedTags = tags.nodes.reduce((acc, current) => {
    return [
      ...acc,
      ...current.keywords.map(tag => tag.trim()),
    ]
  }, [])

  // Create a unique, sorted set of tags:
  const uniqueTags = Array.from(new Set(flattenedTags)).sort()

  // Capture pathname for styling "active" link:
  const pathName =
    typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <Layout>
      <Seo title="Resource Center" />
      <section className="article-hero-sec resource-hero-sec">
        <div className="container">
          <div
            className="row"
            style={{
              backgroundImage: "url(./assets/images/resources-header.jpg)",
            }}
          >
            <div className="col">
              <h6>
                <span>Home</span> | <span>Resource Center</span>
              </h6>
              <h2>Resource Center</h2>
            </div>
          </div>
        </div>
      </section>

      {/* Pet Type Section */}
      <section className="resource-selection-sec">
        <div className="container">
          <div className="row text-center"></div>
          <div className="row text-center resource-slider-row1">
            <div className="col">
              <h5>Pet Type:</h5>
            </div>
            <div className="col">
              <Link to="/resources">
                <span>All</span>
              </Link>
              <Link
                to="/resources/tag/dogs"
                className={pathName === "/resources/tag/dogs" ? "active" : ""}
              >
                <span>Dog</span>
              </Link>
              <Link
                to="/resources/tag/cats"
                className={pathName === "/resources/tag/cats" ? "active" : ""}
              >
                <span>Cat</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Topic Section */}
      <section className="resource-selection-sec">
        <div className="container">
          <div className="row text-center"></div>
          <div className="row text-center resource-slider-row1">
            <div className="col">
              <h5>Topic:</h5>
            </div>
            <div className="col resource-slider-n">
              <Link to="/resources">
                <span>All</span>
              </Link>
              {uniqueTags.map((tag, i) => {
                // Hide dog/cat tags in this row
                const lower = tag.toLowerCase()
                if (lower !== "dogs" && lower !== "cats" && lower !== "Cat" && lower !== "cat" && lower !== "dog" ) {
                  return (
                    <Link
                      to={`/resources/tag/${kebabCase(tag)}`}
                      key={i}
                      className={
                        pathName === `/resources/tag/${kebabCase(tag)}`
                          ? "active"
                          : ""
                      }
                    >
                      <span>{tag}</span>
                    </Link>
                  )
                }
                return null
              })}
            </div>
          </div>
        </div>
      </section>

      {/* Main Articles Grid */}
      <section className="resource-main-content">
        <div className="container">
          <div className="row">
            {allArticles.map(article => (
              <div className="col col-md-4" key={article.id}>
                <div className="resource-main-box">
                  <Link to={`/resources/${article.slug}`}>
                    <img src={article.image.file.url} alt="Article" />
                  </Link>
                  <div className="resource-main-box-inner">
                    <Link to={`/resources/${article.slug}`}>
                      <h5>{article.category}</h5>
                    </Link>
                    <Link to={`/resources/${article.slug}`}>
                      <h4>{article.title}</h4>
                    </Link>
                    <p>{article.excerpt.excerpt}</p>
                    <Link to={`/resources/${article.slug}`} className="link-btn">
                      Read More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.142"
                        height="14.767"
                        viewBox="0 0 22.142 14.767"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-round-forward"
                          data-name="Icon ionic-ios-arrow-round-forward"
                          d="M22,11.534a1,1,0,0,0-.008,1.415l4.676,4.684H8.867a1,1,0,0,0,0,2H26.656L21.98,24.317a1.012,1.012,0,0,0,.008,1.415,1,1,0,0,0,1.407-.008l6.337-6.383h0a1.123,1.123,0,0,0,.208-.315.954.954,0,0,0,.077-.385,1,1,0,0,0-.285-.7L23.4,11.557A.98.98,0,0,0,22,11.534Z"
                          transform="translate(-7.875 -11.252)"
                          fill="#ff0245"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {allArticles?.[0]?.scheduleAVisit && (
        <Visit data={allArticles[0].scheduleAVisit} />
      )}
      
    </Layout>
  )
}

export default Tag

export const pageQuery = graphql`
  query ($tag: String) {
    articles: allContentfulResourceArticles(
      filter: { keywords: { in: [$tag] } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        image {
          file {
            url
          }
        }
        category
        title
        slug
        excerpt {
          excerpt
        }
        keywords
        scheduleAVisit {
          id
          image1 {
            file {
              url
            }
          }
          image2 {
            file {
              url
            }
          }
          title
          content {
            content
          }
          label
          url
          secondaryButtonUrl
          secondaryButtonLabel
        }
      }
    }
    tags: allContentfulResourceArticles {
      nodes {
        keywords
      }
    }
  }
`
